import * as React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { AiFillPlayCircle } from "react-icons/ai";
import Modal from "react-modal";
import ReactPlayer from "react-player";
/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    border: "none",
    borderRadius: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#___gatsby");

function HeroStyleBlog(props) {
  const { mainImage, title, iframe } = props;

  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = () => {
    setVideoURL(iframe);
    setModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <Box
        w="100%"
        h={{ base: "auto" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        // border="1px"
      >
        {/* GRAY BOX */}
        <Box
          w="100%"
          h="496px"
          bgColor="grayBG"
          position="absolute"
          top="0"
          style={{ zIndex: 0 }}
        >
          {/* SVG BOTTOM IMAGE */}
          <SvgCurve top={false} />
        </Box>

        {/* MAIN IMAGE AND TITLE */}
        <Flex
          maxW="857px"
          direction="column"
          mt={{ base: "70px", md: "70px" }}
          style={{ zIndex: 2 }}
          justifyContent="center"
          alignItems="center"
          pb={{ base: "8.29vw", md: "8.29vw", xl: "52px" }}
          // bgColor="blue"
        >
          {/* TITLE */}
          <Text
            maxW={{ base: "93vw", mds: "857px" }}
            color="primary"
            fontSize={{
              base: "28px",
              ms: "32px",
              msx: "36px",
              md: "40px",
              mds: "44px",
              lg: "48px",
              lgs: "50px",
            }}
            fontWeight={{ base: "900", md: "900" }}
            lineHeight={{
              base: "34px",
              ms: "39px",
              msx: "44px",
              md: "49px",
              mds: "54px",
              lg: "58px",
              lgm: "62px",
              lgs: "66px",
              xl: "70px",
            }}
            letterSpacing={{ base: "-0.22px", md: "-0.39px" }}
            // textTransform="uppercase"
            textAlign="center"
            mb={{ base: "42px", md: "46px" }}
            // border="1px"
          >
            {title}
          </Text>

          {/* MAIN IMAGE / COVER */}
          <Flex
            maxW={{
              base: "90vw",
              ssm: "330px",
              ms: "354px",
              msx: "54.1vw",
              md: "54vw",
              lg: "54vw",
              xl: "778px",
            }}
            h={{
              base: "211px",
              ms: "225px",
              msx: "34.38vw",
              md: "34.38vw",
              lg: "34.38vw",
              xl: "495px",
            }}
            position="relative"
            // border="1px"
          >
            {mainImage && (
              <GatsbyImage
                image={mainImage.asset.gatsbyImageData}
                alt={"wana post cover image"}
              />
            )}

            {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
            {props.iframe && (
              <Box
                w="100%"
                h="100%"
                position="absolute"
                top="0"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {/* ICON */}
                <Box
                  w="auto"
                  h="auto"
                  borderRadius="full"
                  cursor="pointer"
                  style={{ zIndex: 2 }}
                  onClick={openModal}
                >
                  <AiFillPlayCircle style={{ fontSize: "92px", color: "#272158" }} />
                </Box>

                {/* ICON WHITE BACKGROUND */}
                <Box
                  w="46px"
                  h="46px"
                  bgColor="white"
                  borderRadius="full"
                  position="absolute"
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>

      {/* MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="watch modal"
      >
        <Box className="player-wrapper">
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            playing={modalIsOpen}
            auto
            url={videoURL}
            controls={true}
          />
        </Box>
      </Modal>
    </>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? { top: "0" } : { bottom: "0" };

  return (
    <Box
      w="100%"
      h={
        top
          ? { base: "2%", ms: "3%", md: "5%", mds: "10%", lg: "15%" }
          : {
              base: "30%",
              md: "25%",
              lg: "30%",
              xl: "30%",
              xxl: "31%",
              sl: "32%",
              ssl: "40%",
            }
      }
      position="absolute"
      left="0"
      style={{ zIndex: 0, ...position }}
    >
      {/* TOP SVG */}
      {top && (
        <svg
          viewBox="0 0 500 149"
          preserveAspectRatio="none"
          style={{ width: "100%", height: "100%" }}
        >
          <path
            fill="white"
            // d="M0.28,122.88 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          ></path>
        </svg>
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg
          viewBox="0 0 500 149"
          preserveAspectRatio="none"
          style={{ width: "100%", height: "100%" }}
        >
          <path
            fill="white"
            d="M-0.27,73.53 C192.15,3.48 325.90,143.59 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          ></path>
        </svg>
      )}
    </Box>
  );
};

export default HeroStyleBlog;
